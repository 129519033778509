import React from "react";
import profilePic from "../assets/profile-pic.jpg";
import { rhythm } from "../utils/typography";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";

function Bio() {
  const data = useStaticQuery(BioQuery);
  const path = "site.siteMetadata";
  const author = get(data, `${path}.author`);
  const description = get(data, `${path}.description`);

  return (
    <div
      style={{
        display: "flex",
        marginBottom: rhythm(2)
      }}
    >
      <img
        src={profilePic}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          width: rhythm(2),
          height: rhythm(2),
          borderRadius: "50%"
        }}
      />
      <p style={{ maxWidth: 310 }}>{description}</p>
    </div>
  );
}

export const BioQuery = graphql`
  query BioQuery {
    site {
      siteMetadata {
        description
        author
      }
    }
  }
`;

export default Bio;
