import React from "react";

import { rhythm } from "../utils/typography";
import ExternalLink from "./ExternalLink";

function Footer() {
  return (
    <footer
      style={{
        marginTop: rhythm(2.5),
        paddingTop: rhythm(1)
      }}
    >
      <div style={{ float: "right" }}>
        <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
          rss
        </a>
      </div>
      <ExternalLink href="https://wo.buchi.la/hello-world/">
        about
      </ExternalLink>
      {" • "}
      <ExternalLink href="https://www.overreacted.io">overreacted</ExternalLink>
      {" • "}
      <ExternalLink href="https://github.com/gaearon/overreacted.io">
        github
      </ExternalLink>
    </footer>
  );
}

export default Footer;
